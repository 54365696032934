<template>
  <div>
    <!--begin::Header -->
    <div
      class="d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="text-white font-weight-bold">
        Beyaz Games
      </h4>
      <span class="btn btn-success btn-sm font-weight-bold font-size-sm mt-2">
        Our Portals
      </span>
    </div>
    <!--end::Header -->

    <!--begin::Nav -->
    <div class="row row-paddingless">
      <!--begin:Item-->
      <template v-for="(item, i) in list">
        <div class="col-6" v-bind:key="i">
          <a v-bind:href="item.link" target="_blank">
            <div
              class="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom"
            >
              <span class="svg-icon svg-icon-3x svg-icon-success">
                <!--begin::Svg Icon-->
                <inline-svg :src="item.svg" />
                <!--end::Svg Icon-->
              </span>
              <span
                class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1"
              >
                {{ item.title }}
              </span>
              <span class="d-block text-dark-50 font-size-lg">
                {{ item.desc }}
              </span>
            </div>
          </a>
        </div>
      </template>
      <!--end:Item-->
    </div>
    <!--end::Nav -->
  </div>
</template>

<script>
export default {
  name: "KTDropdownQuickAction",
  data() {
    return {
      list: [
        {
          title: "Support",
          desc: "Support Center",
          link: "https://support.beyazgames.com",
          svg: process.env.BASE_URL + "media/svg/icons/Communication/Group.svg"
        },
        {
          title: "Status",
          desc: "Situation",
          link: "https://status.beyazgames.com",
          svg: process.env.BASE_URL + "media/svg/icons/Code/Option.svg"
        },
        {
          title: "Android",
          desc: "Android Market",
          link: "https://play.google.com/store/apps/dev?id=7495374081512089912",
          svg: process.env.BASE_URL + "media/svg/icons/Shopping/Box2.svg"
        },
        {
          title: "iOS",
          desc: "Not active yet :(",
          link: "#",
          svg: process.env.BASE_URL + "media/svg/icons/Shopping/Box2.svg"
        }
      ]
    };
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    }
  }
};
</script>
