<template>
  <!-- begin:: Footer -->
  <div
    class="footer kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover"
    id="kt_footer"
    style="background-image: url('media/bg/bg-2.jpg');"
  >
    <!--begin::Container-->
    <div class="container py-lg-18 py-8">
      <!--begin::Row-->
      <div class="row">
        <!--begin::Col-->
        <div class="col-lg-4 my-lg-0 my-5">
          <h4 class="text-white pb-3">About</h4>
          <p class="m-0 text-white opacity-45">
            We’re a game developer from Turkey.<br />
            We want to create games for as many people as possible<br />
            that are played for years and remembered forever.
          </p>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-4 my-lg-0 my-5">
          <h4 class="text-white pb-3">Quick Links</h4>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column mr-18">
              <a
                target="_blank"
                href="https://legal.beyazgames.com/tr/terms-and-conditions.html"
                class="text-white opacity-55 text-hover-primary"
              >
                Terms and Conditions
              </a>
              <a
                target="_blank"
                href="https://legal.beyazgames.com/tr/privacy-policy.html"
                class="py-2 text-white opacity-55 text-hover-primary"
              >
                Privacy Policy
              </a>
              <a
                target="_blank"
                href="https://support.beyazgames.com"
                class="text-white opacity-50 text-hover-primary"
              >
                Support
              </a>
            </div>
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-4 my-lg-0 my-5">
          <h4 class="text-white pb-3">Get In Touch</h4>
          <b-form
            @submit="onsSubmit"
            class="rounded"
            style="background-color: rgba(0,0,0,.2);"
          >
            <div class="input-group p-2 align-items-center">
              <b-form-input
                id="email-1"
                v-model="form.email"
                type="email"
                required
                placeholder="Enter Your Email"
                class="form-control rounded-right border-0 bg-transparent text-white opacity-80"
              ></b-form-input>
              <div class="input-group-append p-0 mr-1">
                <b-button
                  class="btn btn-fh btn-sm btn-primary px-6 rounded-left"
                  type="submit"
                  variant="primary"
                  >Join</b-button
                >
              </div>
            </div>
          </b-form>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="separator separator-solid opacity-7"></div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="container py-8">
      <div
        class="d-flex align-items-center justify-content-between flex-lg-row flex-column"
      >
        <!--begin::Copyright-->
        <div class="d-flex align-items-center order-lg-1 order-2">
          <img
            alt="Logo"
            src="media/logos/logo-main.png"
            class="logo-sticky max-h-35px"
          />
          <span class="text-muted font-weight-bold mx-2"
            >2024 © All Rights Reserved.</span
          >
        </div>
        <!--end::Copyright-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
          <router-link :to="'contact'">
            <a class="text-white opacity-55 pr-2 text-hover-primary">
              Contact
            </a>
          </router-link>
          <a
            href="https://legal.beyazgames.com/tr/imprint.html"
            target="_blank"
            class="text-white opacity-55 px-6 text-hover-primary"
          >
            Imprint
          </a>
          <a
            href="https://status.beyazgames.com/"
            target="_blank"
            class="text-white opacity-55 pr-0 text-hover-primary"
          >
            Status
          </a>
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      sent: false,
      form: {
        email: ""
      }
    };
  },
  name: "KTFooter",
  methods: {
    onsSubmit(evt) {
      evt.preventDefault();
      var instance = axios.create({
        headers: { "content-type": "application/x-www-form-urlencoded" }
      });
      instance
        .post("https://www.beyazgames.com/abone.php", this.form)
        .then(res => {
          this.sent = true;
          alert("Your mail has been added !");
        });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
